import { List } from "@mui/material";
import { selectSharedLayoutContent } from "components/shared/layout/store/layoutContentSlice";
import { m } from "framer-motion";
import { useAppSelector } from "lib/redux";
import { NavItemVariant } from ".";
import { selectMainLayoutContent } from "../../store/layoutContentSlice";
import { selectMainNavState } from "../store/stateSlice";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

function Navigation() {
  const { navDrawerOpen } = useAppSelector(selectMainNavState);
  const {} = useAppSelector(selectSharedLayoutContent);
  const {
    mainNavDrawer: { items },
  } = useAppSelector(selectMainLayoutContent);

  return (
    <List
      className="h-full space-y-1.5 whitespace-nowrap py-0"
      component={m.ul}
      initial={false}
      animate={navDrawerOpen ? "open" : "closed"}
      variants={variants}
      sx={{
        "& .nav-list-item": {
          height: "48px",

          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.05)",
          },
          "&:focus:not(.active)": {
            backgroundColor: "rgba(255, 255, 255, 0.06)",
          },
        },
      }}
    >
      {items.map((item, i) => (
        <NavItemVariant key={i} item={item} nestedLevel={0} />
      ))}
    </List>
  );
}

export default Navigation;
