import { ListItemButton, ListItemText } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import clsx from "lib/clsx";
import { useAppDispatch } from "lib/redux";
import { INavDrawerItemProps } from "models/main/shared";
import Link from "next/link";
import { useRouter } from "next/router";
import { setNavState } from "../store/stateSlice";

function NavItem({ item, nestedLevel }: INavDrawerItemProps) {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const { pathname } = useRouter();

  const active = item.url === pathname;
  const nested = nestedLevel > 0;
  const itemPadding = nested ? 38 + nestedLevel * 16 : 16;

  const handleOnClick = () => {
    if (item.url) dispatch(setNavState({ navDrawerOpen: false }));
  };

  return (
    <ListItemButton
      className={clsx(
        active && "pointer-events-none bg-white/20",
        "nav-list-item mb-1 flex gap-4 rounded-xl py-2.5 text-dark",
      )}
      LinkComponent={Link}
      href={item.url ?? ""}
      onClick={handleOnClick}
      sx={{
        paddingLeft: (itemPadding > 80 ? 80 : itemPadding) + "px",
        color: alpha(theme.palette.secondary.contrastText, active ? 1 : 0.7),
        "&:hover": {
          color: theme.palette.secondary.contrastText,
        },
      }}
    >
      <ListItemText
        primary={item.title}
        classes={{
          primary: clsx(!nested && "!font-bold", "text-dark truncate"),
        }}
        sx={{
          "& > .MuiListItemText-primary": {
            font: theme.typography.p3,
          },
        }}
      />
    </ListItemButton>
  );
}

export default NavItem;
