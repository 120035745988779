import { Backdrop, Box, Divider, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Button, Image } from "components/shared";
import { selectSharedLayoutContent } from "components/shared/layout/store/layoutContentSlice";
import { Variants, m } from "framer-motion";
import { useDebounce, useScrollLock } from "hooks";
import clsx from "lib/clsx";
import { useAppDispatch, useAppSelector } from "lib/redux";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Navigation } from ".";
import { MenuToggle } from "..";
import { selectMainLayoutContent } from "../../store/layoutContentSlice";
import { selectMainNavState, setNavState } from "../store/stateSlice";

const sidebarVariants: Variants = {
  open: {
    clipPath: `inset(-1000px -1000px calc(-100% - 0px) calc(-150% - 0px) round 9999px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
  closed: {
    clipPath: `inset(12px 30px calc(100% - 50px) calc(100% - 118px) round 4px)`,
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const buttonWrapperVariants: Variants = {
  open: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.4,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const buttonVariants: Variants = {
  open: {
    scale: 1,
    opacity: 1,
    transition: {
      scale: { duration: 0.7, ease: [0.5, 2, 0.5, 0.5] },
    },
  },
  closed: {
    scale: 0,
    opacity: 0,
    transition: {
      scale: { stiffness: 1000 },
    },
  },
};

export const NavDrawer = () => {
  const dispatch = useAppDispatch();
  const { navDrawerOpen } = useAppSelector(selectMainNavState);
  const { logos } = useAppSelector(selectSharedLayoutContent);
  const {
    mainNavDrawer: { items },
  } = useAppSelector(selectMainLayoutContent);

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { pathname } = useRouter();
  const [lockScroll, unlockScroll] = useScrollLock();

  const handleDrawerToggle = () => {
    dispatch(setNavState({ navDrawerOpen: !navDrawerOpen }));
  };

  const handleDrawerClose = () => {
    dispatch(setNavState({ navDrawerOpen: false }));
  };

  const delayedTransition = useDebounce(navDrawerOpen, navDrawerOpen ? 0 : 1000);

  useEffect(() => {
    (mdDown && navDrawerOpen ? lockScroll : unlockScroll)();
  }, [lockScroll, mdDown, navDrawerOpen, unlockScroll]);

  return (
    <Box
      className="sm:hidden"
      component={m.nav}
      initial={false}
      animate={navDrawerOpen ? "open" : "closed"}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer,
      }}
    >
      <Backdrop
        className="!transition-all !duration-700"
        open={navDrawerOpen || (!navDrawerOpen && delayedTransition)}
        onClick={handleDrawerClose}
      />
      <m.div className="fixed inset-y-0 right-0 w-full max-w-[332px]" variants={sidebarVariants}>
        <div
          className={clsx(
            "flex h-full flex-col px-8 pb-4 pt-3",
            (navDrawerOpen || delayedTransition) && "bg-primary-dark",
          )}
        >
          <div className="mt-1.5 flex items-start justify-between">
            <Link className="mt-20" href="/" onClick={handleDrawerClose}>
              <Image
                className="h-auto max-w-56 select-none drop-shadow-sm"
                {...logos.dark}
                placeholder="empty"
              />
            </Link>
            <MenuToggle toggle={handleDrawerToggle} />
          </div>
          <Divider className="mb-8 mt-8 border-primary-main" />
          <Box className="flex flex-1 flex-col overflow-auto overflow-x-hidden text-center text-secondary-main">
            <Navigation />
            <Divider className="bg-primary-main" />
            <m.div
              className="flex-center mb-4 mt-8 w-full flex-wrap gap-4"
              initial={false}
              animate={navDrawerOpen ? "open" : "closed"}
              variants={buttonWrapperVariants}
            >
              {items
                .filter((item) => item.variant === "button")
                .map(({ title, url }, i) => {
                  const activePath = url === pathname;

                  return (
                    <m.div key={i} className="flex-1 shrink-0" variants={buttonVariants}>
                      <Button
                        className={clsx(
                          "h-16 w-full bg-background-default text-light transition duration-500 hover:bg-primary-light",
                          url === "/auth/register" &&
                            "bg-primary-light hover:bg-primary-main hover:text-dark",
                          activePath && "bg-primary-main text-dark",
                        )}
                        {...(url ? { LinkComponent: Link, href: url } : {})}
                        variant="contained"
                        size="small"
                        onClick={() => {
                          unlockScroll();
                        }}
                      >
                        {title}
                      </Button>
                    </m.div>
                  );
                })}
            </m.div>
          </Box>
        </div>
      </m.div>
    </Box>
  );
};

export default NavDrawer;
