import { ChevronRight } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText, useMediaQuery } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import clsx from "lib/clsx";
import { useAppDispatch } from "lib/redux";
import { INavDrawerItemProps, INavItem } from "models/main/shared";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { NavItemVariant } from ".";
import { setNavState } from "../store/stateSlice";

function isPathInChildren(parent: INavItem, path: string) {
  if (!parent.children) return false;

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children && isPathInChildren(parent.children[i], path)) return true;
    if (parent.children[i].url === path) return true;
  }

  return false;
}

function NavCollapse({ item, nestedLevel }: INavDrawerItemProps) {
  const dispatch = useAppDispatch();

  const { pathname } = useRouter();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const [open, setOpen] = useState(false);
  const pathInChildren = useMemo(() => isPathInChildren(item, pathname), [item, pathname]);
  const itemPadding = nestedLevel > 0 ? 38 + nestedLevel * 16 : 16;

  useEffect(() => {
    if (pathInChildren) setOpen(true);
  }, [pathInChildren, lgUp]);

  const handleOnClick = () => {
    setOpen(!open);
    if (item.url) dispatch(setNavState({ navDrawerOpen: false }));
  };

  return (
    <List
      className={clsx((open || pathInChildren) && "rounded-xl bg-white/5", "mb-1")}
      disablePadding
    >
      <ListItemButton
        role="listitem"
        className={clsx(open && "mb-1 bg-white/5", "nav-list-item flex gap-4 rounded-xl py-2.5")}
        component={item.url ? Link : "li"}
        href={item.url}
        shallow={!!item.url || undefined}
        onClick={handleOnClick}
        sx={{
          paddingLeft: (itemPadding > 80 ? 80 : itemPadding) + "px",
          color: alpha(theme.palette.secondary.contrastText, 0.7),
          transition: "margin 0.3s",

          "&:hover": {
            color: theme.palette.secondary.contrastText,
          },
        }}
      >
        <ListItemText
          primary={item.title}
          classes={{
            primary: clsx("!font-bold truncate text-dark"),
          }}
          sx={{
            "& > .MuiListItemText-primary": {
              font: theme.typography.p3,
            },
          }}
        />
        <ChevronRight className={clsx(open && "rotate-90", "text-dark")} />
      </ListItemButton>
      {item.children && (
        <Collapse in={open} component="li">
          {item.children.map((item, i) => (
            <List key={i} disablePadding>
              <NavItemVariant item={item} nestedLevel={nestedLevel + 1} />
            </List>
          ))}
        </Collapse>
      )}
    </List>
  );
}

export default NavCollapse;
