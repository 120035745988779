import { m } from "framer-motion";
import { INavDrawerItemProps } from "models/main/shared";
import { NavCollapse, NavItem } from ".";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const NavItemVariant = (props: INavDrawerItemProps) => {
  return (
    <m.li variants={variants}>
      {props.item.variant === "collapse" ? (
        <NavCollapse {...props} />
      ) : props.item.variant === "text" ? (
        <NavItem {...props} />
      ) : null}
    </m.li>
  );
};

export default NavItemVariant;
